import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useGetAdvisorsQuery } from '@store/services/users';
import { setAdvisorOptions } from '@store/slices/advisorIdSlice';
import { Select, Option } from '@frontend-components/components/Select';
import { AdvisorTypes } from '@frontend-components/constants';
import { StyledAdvisors, StyledAdvisorsInfo, ChooseAdvisor } from './Advisors.styles';
import { useGetBasketQuery } from '@store/services/payment';
import { useFlag } from '@unleash/proxy-client-react';
const Advisors = () => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const isEcommerceEnabled = useFlag('EcommerceON');
    const dispatch = useAppDispatch();
    const userId = useAppSelector((state) => { var _a; return (_a = state.userId.payload) === null || _a === void 0 ? void 0 : _a.userId; });
    const { data: basketData } = useGetBasketQuery({ userId: Number(userId) }, { skip: userId == null || isEcommerceEnabled });
    const products = useAppSelector((state) => state.products);
    const productsPayload = isEcommerceEnabled
        ? { products }
        : { basket_id: (_a = basketData === null || basketData === void 0 ? void 0 : basketData.id.toString()) !== null && _a !== void 0 ? _a : '' };
    const ecomSkipCondition = isEcommerceEnabled ? products == null : (basketData === null || basketData === void 0 ? void 0 : basketData.id) == null;
    const { data: advisorsData, isLoading: isAdvisorsLoading } = useGetAdvisorsQuery(Object.assign({ user_id: userId !== null && userId !== void 0 ? userId : 0 }, productsPayload), {
        skip: ecomSkipCondition || userId == null
    });
    const { selectedAdvisorId, selectedAdvisorType } = useAppSelector((state) => state.advisorId);
    const handleChangeAdvisorId = (value) => {
        if (Number(value) !== selectedAdvisorId && value != null && value) {
            dispatch(setAdvisorOptions({
                selectedAdvisorId: Number(value)
            }));
        }
    };
    const handleChangeAdvisorType = (value) => {
        if (value !== selectedAdvisorType && value != null && value) {
            dispatch(setAdvisorOptions({
                selectedAdvisorType: value
            }));
        }
    };
    useEffect(() => {
        if (!(advisorsData === null || advisorsData === void 0 ? void 0 : advisorsData.is_Futre_POW)) {
            dispatch(setAdvisorOptions({
                selectedAdvisorType: AdvisorTypes.all
            }));
            return;
        }
        dispatch(setAdvisorOptions({
            selectedAdvisorType: AdvisorTypes.student
        }));
    }, [advisorsData === null || advisorsData === void 0 ? void 0 : advisorsData.is_Futre_POW]);
    return (_jsxs(StyledAdvisors, { children: [_jsx(ChooseAdvisor, { children: t('bookingTheAdvisors.chooseYourAdvisor') }), _jsxs(StyledAdvisorsInfo, { children: [_jsx(Select, { onChange: (value) => handleChangeAdvisorType(value), name: t('bookingTheAdvisors.advisorsTypes'), label: t('bookingTheAdvisors.selectAdvisorType'), isLoading: isAdvisorsLoading, selectExtraProps: {
                            'data-cy': 'advisor-type-select'
                        }, value: selectedAdvisorType, children: Object.keys(AdvisorTypes).map((advisorType) => (_jsx(Option, { "data-cy": `advisor-type-select-option-${advisorType}`, value: advisorType, children: t(`global.advisorTypes.${advisorType}`) }, advisorType))) }), _jsxs(Select, { onChange: (value) => handleChangeAdvisorId(value), name: t('bookingTheAdvisors.advisors'), label: t('bookingTheAdvisors.selectAdvisor'), selectExtraProps: {
                            'data-cy': 'advisor-select'
                        }, value: String(selectedAdvisorId), isSearchable: true, children: [_jsx(Option, { value: "0", children: t('bookingTheAdvisors.allAdvisors') }, "all"), selectedAdvisorType === AdvisorTypes.all
                                ? (_b = advisorsData === null || advisorsData === void 0 ? void 0 : advisorsData.data) === null || _b === void 0 ? void 0 : _b.map((advisor) => (_jsx(Option, { value: String(advisor.id), children: advisor.name }, advisor.id)))
                                : (_c = advisorsData === null || advisorsData === void 0 ? void 0 : advisorsData.data) === null || _c === void 0 ? void 0 : _c.filter((advisor) => advisor.legal_advisor_type === selectedAdvisorType).map((advisor) => (_jsx(Option, { "data-cy": `advisor-select-option-${advisor.id}`, value: String(advisor.id), children: advisor.name }, advisor.id)))] }, selectedAdvisorType)] })] }));
};
export default Advisors;
