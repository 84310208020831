var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useGetAdvisorsQuery, useGetUsersQuery } from '@store/services/users';
import { setManualBookingOptions } from '@store/slices/manualBookingSlice';
import { usePostNewBookingMutation, usePutUpdateBookingMutation } from '@store/services/booking';
import { useGetProductsQuery } from '@store/services/product';
import { dateAdd, getIdentifier, hasRealEstateItems } from './ManualBooking.utils';
import { getUrlParam } from '@frontend-components/utils/urls';
import { Select, Option } from '@frontend-components/components/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from '@frontend-components/components/Input';
import { faArrowLeftLong } from '@fortawesome/pro-regular-svg-icons';
import { StyledManualBooking, StyledPropertyType, StyledSelectAdvisor, StyledTimeEnd, StyledTimeStart, StyledTimeStartWrapper } from './ManualBooking.styles';
import { StyledButtonsWrapper, StyledBookingButton } from '@components/BookTime/BookTime.styles';
import { TempBackButtonWrapper, StyledBackWrapper } from '../../containers/CreateBooking/CreateBooking.styles';
import { ManualBookingRepaPropertyTypes } from '@constants';
import { BookingStatus } from '@store/services/booking.types';
import { addCompleted, handleBack, handleNext, removeCompleted } from '@store/slices/bookingWizardSlice';
import { useGetBasketQuery } from '@store/services/payment';
import { useFlag } from '@unleash/proxy-client-react';
const ManualBooking = () => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const bookingId = Number(getUrlParam(window.location.href, 'booking_id'));
    const adminName = getUrlParam(window.location.href, 'admin_name');
    const userId = useAppSelector((state) => { var _a; return (_a = state.userId.payload) === null || _a === void 0 ? void 0 : _a.userId; });
    const products = useAppSelector((state) => state.products);
    const { data: userData } = useGetUsersQuery({ userId: Number(userId) }, { skip: userId == null });
    const { bookingMinutes, manualMinutes } = useAppSelector((state) => state.availability);
    const { data: productsData } = useGetProductsQuery();
    const isEcommerceEnabled = useFlag('EcommerceON');
    const { data: basketData } = useGetBasketQuery({ userId: Number(userId) }, { skip: userId == null || isEcommerceEnabled });
    const productsInPayload = isEcommerceEnabled
        ? { products }
        : { basket_id: (_a = basketData === null || basketData === void 0 ? void 0 : basketData.id.toString()) !== null && _a !== void 0 ? _a : '' };
    const productsSkipCondition = isEcommerceEnabled
        ? products == null
        : (basketData === null || basketData === void 0 ? void 0 : basketData.id) == null;
    const { data: advisorsData, isLoading: isAdvisorsLoading } = useGetAdvisorsQuery(Object.assign(Object.assign({}, productsInPayload), { user_id: userId !== null && userId !== void 0 ? userId : 0 }), { skip: productsSkipCondition || userId === 0 });
    const basketSpecificPayload = {
        basket_id: (_b = basketData === null || basketData === void 0 ? void 0 : basketData.id.toString()) !== null && _b !== void 0 ? _b : '',
        products: basketData === null || basketData === void 0 ? void 0 : basketData.items.map((item) => ({
            product_type: item.product,
            purchase_type: item.purchase_type,
            identifier: item.identifier != null
                ? getIdentifier(item.identifier)
                : `user:${userId}`
        }))
    };
    const productsSpecificPayload = {
        products: products.map((product) => ({
            product_type: product.productName,
            purchase_type: product.purchaseType
        }))
    };
    const payload = isEcommerceEnabled ? productsSpecificPayload : basketSpecificPayload;
    const { selectAdvisorTimekitId, selectPropertyType, selectManualDate, selectStartTime } = useAppSelector((state) => state.manualBooking);
    const [addBooking, { isLoading: isNewBookingLoading, isSuccess: isNewBookingSuccess }] = usePostNewBookingMutation();
    const [updateBooking, { isLoading: isUpdateBookingLoading, isSuccess: isUpdateBookingSuccess }] = usePutUpdateBookingMutation();
    const handleClickCreateManualBooking = () => __awaiter(void 0, void 0, void 0, function* () {
        const isMissingSelectedData = selectAdvisorTimekitId == null
            || selectManualDate == null
            || bookingMinutes === 0;
        const isMissingUserData = userId == null || (userData === null || userData === void 0 ? void 0 : userData.fullname) == null;
        if (products == null || isMissingSelectedData || isMissingUserData)
            return;
        if (bookingId) {
            yield updateBooking(Object.assign(Object.assign({ bookingId, status: BookingStatus.Rebooked, is_paid: false, origin: 'backend' }, payload), { date: `${selectManualDate} ${selectStartTime}`, timekit_id: selectAdvisorTimekitId, minutes: bookingMinutes, send_teams_meeting_link: false, status_changed_by: adminName || '', is_manual: true, property_type: selectPropertyType, manual_minutes: manualMinutes }));
        }
        else {
            yield addBooking(Object.assign(Object.assign({ user_id: userId, booked_by: adminName !== null && adminName !== void 0 ? adminName : ((userData === null || userData === void 0 ? void 0 : userData.fullname) || ''), is_paid: false, origin: 'backend' }, payload), { date: `${selectManualDate} ${selectStartTime}`, timekit_id: selectAdvisorTimekitId, minutes: bookingMinutes, send_teams_meeting_link: false, is_manual: true, property_type: selectPropertyType, manual_minutes: manualMinutes }));
        }
        dispatch(setManualBookingOptions({
            selectAdvisorTimekitId: '',
            selectPropertyType: '',
            selectManualDate: '',
            selectStartTime: ''
        }));
    });
    useEffect(() => {
        if (isNewBookingSuccess || isUpdateBookingSuccess) {
            dispatch(handleNext());
            dispatch(addCompleted(2));
        }
    }, [isNewBookingSuccess, isUpdateBookingSuccess]);
    const hasRealEstate = useMemo(() => {
        const cartProducts = isEcommerceEnabled ? products : basketData === null || basketData === void 0 ? void 0 : basketData.items;
        if (cartProducts == null || productsData == null)
            return false;
        return hasRealEstateItems(cartProducts, productsData);
    }, [products, basketData]);
    const handleBackClick = () => {
        dispatch(handleBack());
        dispatch(removeCompleted(1));
    };
    const endTime = (() => {
        if (selectManualDate.length === 0
            || selectStartTime.length === 0
            || bookingMinutes == null
            || bookingMinutes === 0)
            return '';
        const date = new Date(`${selectManualDate}, ${selectStartTime}`);
        const newTime = dateAdd(date, 'minute', bookingMinutes);
        return newTime.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
        });
    })();
    return (_jsxs(StyledManualBooking, { children: [_jsxs(StyledSelectAdvisor, { children: [_jsx("p", { "data-cy": "create-booking-advisor-title", children: t('bookingTheAvailability.selectAdvisorForBooking') }), _jsx(Select, { onChange: (value) => dispatch(setManualBookingOptions({
                            selectAdvisorTimekitId: String(value)
                        })), name: t('bookingTheAdvisors.advisorsTypes'), label: t('bookingTheAdvisors.selectAdvisorType'), value: selectAdvisorTimekitId, isSearchable: true, isLoading: isAdvisorsLoading, selectExtraProps: {
                            'data-cy': 'create-booking-advisor'
                        }, children: (_c = advisorsData === null || advisorsData === void 0 ? void 0 : advisorsData.data) === null || _c === void 0 ? void 0 : _c.map((advisor) => (_jsx(Option, { "data-cy": `create-booking-advisor-option-${advisor.id}`, value: advisor.timekit_id, children: advisor.name }, advisor.id))) })] }), hasRealEstate && (_jsxs(StyledPropertyType, { children: [_jsx("p", { children: t('bookingTheAvailability.selectProperty') }), _jsx(Select, { onChange: (value) => dispatch(setManualBookingOptions({
                            selectPropertyType: String(value)
                        })), name: t('bookingTheAvailability.selectProperty'), label: t('bookingTheAvailability.selectProperty'), value: selectPropertyType, isSearchable: true, selectExtraProps: {
                            'data-cy': 'create-booking-repaProperty'
                        }, children: Object.keys(ManualBookingRepaPropertyTypes).map((repaPropertyType) => (_jsx(Option, { "data-cy": `create-booking-repaProperty-option-${repaPropertyType}`, value: repaPropertyType, children: t(`bookingRepaPropertyTypes.${repaPropertyType}`) }, repaPropertyType))) })] })), _jsxs(StyledTimeStartWrapper, { children: [_jsx("p", { children: t('bookingTheAvailability.chooseMeetingStart') }), _jsxs(StyledTimeStart, { children: [_jsx(Input, { "data-cy": "create-booking-date", type: "date", name: t('bookingTheAvailability.chooseMeetingStart'), onChange: (event) => dispatch(setManualBookingOptions({
                                    selectManualDate: event.target.value
                                })), value: selectManualDate }), _jsx(Input, { "data-cy": "create-booking-time", type: "time", name: t('bookingTheAvailability.chooseMeetingStart'), onChange: (event) => dispatch(setManualBookingOptions({
                                    selectStartTime: event.target.value
                                })), value: selectStartTime })] })] }), _jsxs(StyledTimeEnd, { children: [_jsx("p", { children: t('bookingTheAvailability.meetingEndTime') }), _jsx("p", { children: endTime })] }), _jsxs(StyledButtonsWrapper, { children: [_jsxs(TempBackButtonWrapper, { onClick: handleBackClick, children: [_jsx(FontAwesomeIcon, { icon: faArrowLeftLong }), _jsx(StyledBackWrapper, { children: t('bookingTheAvailability.back') })] }), _jsx(StyledBookingButton, { "data-cy": "create-booking-submit", disabled: !selectAdvisorTimekitId
                            || !selectManualDate
                            || !selectStartTime
                            || (hasRealEstate && !selectPropertyType), isLoading: isNewBookingLoading || isUpdateBookingLoading, onClick: handleClickCreateManualBooking, color: "pink", children: t('bookingTheAvailability.saveBooking') })] })] }));
};
export default ManualBooking;
