import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { paymentApi, PAYMENT_API_REDUCER_KEY } from '@store/services/payment';
import { productApi, BOOKING_API_PRODUCT_KEY } from '@store/services/product';
import { usersApi, BOOKING_API_USERS_KEY } from '@store/services/users';
import { availabilityApi, BOOKING_API_AVAILABILITY_KEY } from '@store/services/booking';
import { advisorIdSlice } from '@store/slices/advisorIdSlice';
import { userIdSlice } from '@store/slices/userIdSlice';
import { availabilitySlice } from '@store/slices/availabilitySlice';
import bookingWizardSlice from '@store/slices/bookingWizardSlice';
import { manualBookingSlice } from '@store/slices/manualBookingSlice';
import { createProductSlice } from '@store/slices/createProductSlice';
import errorMiddleware from './middleware/errorMiddleware';
import ecommerceSlice from './slices/ecommerceSlice';
const storeReducers = combineReducers({
    [BOOKING_API_PRODUCT_KEY]: productApi.reducer,
    [PAYMENT_API_REDUCER_KEY]: paymentApi.reducer,
    [BOOKING_API_USERS_KEY]: usersApi.reducer,
    [BOOKING_API_AVAILABILITY_KEY]: availabilityApi.reducer,
    advisorId: advisorIdSlice.reducer,
    userId: userIdSlice.reducer,
    availability: availabilitySlice.reducer,
    bookingWizard: bookingWizardSlice,
    manualBooking: manualBookingSlice.reducer,
    createProduct: createProductSlice.reducer,
    products: ecommerceSlice
});
export const store = configureStore({
    reducer: storeReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(productApi.middleware, paymentApi.middleware, usersApi.middleware, availabilityApi.middleware).concat(errorMiddleware)
});
export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
