import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@store/index';
import { addCompleted, handleNext } from '@store/slices/bookingWizardSlice';
import { setProducts } from '@store/slices/ecommerceSlice';
import Button from '@frontend-components/components/Button';
import { getUrlParam } from '@frontend-components/utils/urls';
import { CreateProductWrapper } from '../../containers/CreateProduct/CreateProduct.styles';
import { StyledNextStep } from '@components/Basket/Basket.styles';
import { StyledWrapper } from '../../global.styles';
import { ecommerceUrl } from '@constants';
const Ecommerce = () => {
    const dispatch = useAppDispatch();
    const products = useAppSelector((state) => state.products);
    const { t } = useTranslation();
    const userId = getUrlParam(window.location.href, 'user_id');
    useEffect(() => {
        window.addEventListener('message', (event) => {
            var _a, _b;
            if (event.data == null || !Array.isArray(event.data)) {
                const isExceedMinutesLimitError = ((_b = (_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) === t('bookingTheBasket.minutesOverLimit');
                if (isExceedMinutesLimitError)
                    return;
                dispatch(setProducts([]));
                return;
            }
            const { data } = event;
            dispatch(setProducts(data));
            dispatch(addCompleted(1));
        });
        return () => {
            window.removeEventListener('message', () => { });
        };
    }, []);
    return (_jsxs(CreateProductWrapper, { children: [_jsx(StyledWrapper, { children: _jsx("iframe", { style: {
                        width: '100%',
                        height: '750px',
                        border: 'none'
                    }, src: `${ecommerceUrl}/da/order-edit?user-id=${userId}`, title: "ECommerce" }) }), _jsx(StyledNextStep, { children: _jsx(Button, { disabled: products.length === 0, "data-cy": "basket-overview-edit-next-step", onClick: () => dispatch(handleNext()), color: "pink", children: t('bookingTheBasket.nextStep') }) })] }));
};
export default Ecommerce;
