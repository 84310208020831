import { getServiceUrlFor } from '@frontend-components/utils/urls';
export const bookingApiUrl = process.env.BOOKING_URL;
export const paymentApiUrl = process.env.APP_ENV !== 'production'
    ? process.env.PAYMENT_URL
    : getServiceUrlFor(window.location.href, 'payment');
export const ecommerceUrl = process.env.APP_ENV !== 'production'
    ? process.env.ECOMMERCE_URL
    : getServiceUrlFor(window.location.href, 'ecommerce');
export const administrationApiUrl = process.env.APP_ENV !== 'production'
    ? process.env.APPLICATION_URL
    : getServiceUrlFor(window.location.href, 'app');
window.booking = {
    administrationApiUrl,
    bookingUrl: process.env.BOOKING_URL,
    appEnv: process.env.APP_ENV
};
export var TimeIntervals;
(function (TimeIntervals) {
    TimeIntervals["day"] = "day";
    TimeIntervals["before_dinner"] = "before_dinner";
    TimeIntervals["after_dinner"] = "after_dinner";
    TimeIntervals["evening"] = "evening";
})(TimeIntervals || (TimeIntervals = {}));
export var AvailabilityPriceFilters;
(function (AvailabilityPriceFilters) {
    AvailabilityPriceFilters["best_price"] = "best_price";
    AvailabilityPriceFilters["all_prices"] = "";
})(AvailabilityPriceFilters || (AvailabilityPriceFilters = {}));
export var ManualBookingRepaPropertyTypes;
(function (ManualBookingRepaPropertyTypes) {
    ManualBookingRepaPropertyTypes["housing_cooperative"] = "housing_cooperative";
    ManualBookingRepaPropertyTypes["entreprise"] = "entreprise";
    ManualBookingRepaPropertyTypes["owned_home"] = "owned_home";
    ManualBookingRepaPropertyTypes["family_transfer"] = "family_transfer";
    ManualBookingRepaPropertyTypes["agrictultural"] = "agricultural";
    ManualBookingRepaPropertyTypes["project_purchase"] = "project_purchase";
    ManualBookingRepaPropertyTypes["summerhouse"] = "summerhouse";
    ManualBookingRepaPropertyTypes["forced_auction"] = "forced_auction";
    ManualBookingRepaPropertyTypes["ground"] = "ground";
})(ManualBookingRepaPropertyTypes || (ManualBookingRepaPropertyTypes = {}));
