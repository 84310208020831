import { createSlice } from '@reduxjs/toolkit';
const initialState = [];
const ecommerceSlice = createSlice({
    name: 'ecommerce',
    initialState,
    reducers: {
        setProducts: (_, action) => action.payload
    }
});
export const { setProducts } = ecommerceSlice.actions;
export default ecommerceSlice.reducer;
